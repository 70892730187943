import { Routes as routes } from '../config';
import { type FunctionComponent } from 'react';
import { NavLink, generatePath } from 'react-router-dom';
import EwbLogoPNG from '../assets/image/ewb-logo-white.png';
import WelcomeName from './WelcomeName';
import { type AuthState } from '../store/authSlice';
import { useIsAuthenticated } from '@azure/msal-react';
import { LoggedInUserInfo } from '../hooks';
import { CanViewPage } from '../utils';

const Navbar: FunctionComponent = () => {
    const isAuthenticated = useIsAuthenticated();
    const userInfo: AuthState = LoggedInUserInfo();
    return (
        <nav role="navigation" className="navbar" aria-label="main navigation">
            <div className="navbar-wrapper">
                <div className="brand-wrapper">
                    <img width="auto" aria-hidden alt="EastWestBank-logo" src={EwbLogoPNG} />
                </div>
                <div className="navbar-routes">
                    {isAuthenticated && routes
                        .filter(({ isPrivate, showInNav }) => showInNav && (CanViewPage(isPrivate, userInfo)))
                        .map(({ path, name, params }) => (
                            <NavLink
                                key={name}
                                to={generatePath(path, params)}
                                className={'navbar-item is-active'}
                            >
                                {name}
                            </NavLink>
                        ))}
                </div>
                <WelcomeName />
            </div>
        </nav>
    );
};

export default Navbar;
